import React from 'react'
import { Container } from 'react-bootstrap'

import * as s from './Quote.module.scss'

const Quote = ({ data }) => (
  <Container as="section" className={s.quote_wrapper}>
    <blockquote cite="cosmetology.ua" className={s.quote}>
      <h2 className={s.quote_title}>{data}</h2>
    </blockquote>
  </Container>
)

export default Quote
